html,body,#root{
    height: 100%;
    width: 100%;
}

.wholepage{
    width:100%;
    height: 100%;
}
.retailContent {
    display: flex;
    height: 100%;
    /* flex-direction: column; */
}

.retailSide1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 0;
}
.retailSide1 span{
        font-size: 10px;
}
.retailName {
    font-size: 25px;
    /* padding: 50px 0; */
}
.retailSide {
    display: flex;
    flex: 0.3 1;
    box-shadow: 2px 2px 12px;
    flex-direction: column;
    justify-content: space-around;
}
.retailSide2 {
    display: flex;
    height: 40%;
    /* width: 30vw; */
    gap: 20px;
    padding: 10px;
    background-color: grey;
    /* justify-content: center; */
    overflow-x: auto;
    object-fit: cover;
}
.retailSide2 img {
    width: 100%;
    object-fit: contain;
}
.retailSide3 {
    display: flex;
    justify-content: space-around;
}
.retailBox {
    display: flex;
    flex-direction: column;
    /* flex: 1; */
    justify-content: center;
    /* align-items: center; */
    /* padding: 0 10% 0; */
    padding: 15px;
    border: 3px dotted grey;
    margin: 10px;
}

.retailBoxHead{
    display: flex;
}
.statsBoxContainer{
    display: flex;
    flex-direction: column;
}
.retailInfo {
    display: flex;
    flex: 0.7 1;
    padding: 5% 0 0 1%;
    flex-direction: column;
}
.bottlecontainer{
    height: 100%;
    width: 100%;
}
.bottlemodal{
    width:300px;
    height:300px;
    display:"flex";
    justify-content:"center";
    align-items:"center";
    background:"white";
    top:50%;
    left:50%;
}
.retailNavButton{
    cursor: pointer;
}
