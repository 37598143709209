.header{
    background-color: #1b1c1c;
    display: grid;  
    grid-template-columns: 50px 1fr;
    align-items: center;

}

.header .image{
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.image img{
    width: 100%;
}

.header h5{
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}