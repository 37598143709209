.container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.companyDetails {
    display: flex;
    font-size: 50px;
    justify-content: center;
    margin: 50px;
}
.campaignBox {
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 25px 0;
    flex: 1 1;
    /* border-radius: 43px; */
}
.select {
    display: flex;
    justify-content: center;
}
.campaignContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 8px;
    flex-direction: column;
    width: 30%;
    border-radius: 5px;
}
.campaignItem {
    padding: 10px;
    cursor: pointer;
}
.campaignDetails {
    display: flex;
    gap: 10px;
    padding: 2px;
}
.campaignId {
    display: flex;
    font-size: 14px;
}
.campaignName {
    display: flex;
    font-size: 17px;
    font-weight: 600;
}
.campaignInfo {
    display: flex;
    font-size: 14px;
}
