.wholehere{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.logincontainer {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: auto;
    align-items: center;
    /* gap: 10px; */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 6px grey;
}
.logintitle {
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 25px 0;
}
.loginimg {
    width: 80%;
    height: 90px;
    background-image: url("../Assets/Logo.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.logintxt {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}

